import React, { useEffect } from 'react'
import LoginForm from '../../components/LoginForm'
import SplashScreenModalDialog from '../../components/GUI/SplashScreenModalDialog'
import { useSelector } from 'react-redux'
import VersionChecker from '../../components/VersionChecker'

const Login = () => {
    const user = useSelector(state => state.user)

    useEffect(() => {
        if (user) {
            // return <Redirect to={{ pathname: '/', state: { from: '/login' } }}/>
        }
    }, [user])

    return <VersionChecker><SplashScreenModalDialog body={<LoginForm/>}/></VersionChecker>
}

export default Login
