import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user, { isSuperAdminImpersonating } from './user'
import charts from './chart'
import archive from './archive'
import header from './header'
import intercom from './intercom'
import search from './search'
import chartDetail from './chartDetail'
import reports from './reports'
import context from './context'
import workspaces from './workspaces'
import modalDialog from './modalDialog'
import modalWizard from './modalWizard'
import team from './team'
import webapp from './webapp'

const createReducer = (history) => {
    const appReducer = combineReducers({
        router: connectRouter(history),
        user,
        charts,
        header,
        intercom,
        search,
        workspaces,
        chartArchive: archive,
        chartDetail,
        reports,
        context,
        modalDialog,
        modalWizard,
        team,
        webapp
    })

    const switchToWorkspaceAndCompany = (user, workspace, company, webapp, statePath, actionPath) => {
        const isDefaultContextOfUser = user.company.id === company.id && user.defaultWorkspace.key === workspace.key
        const isDefaultWorkspaceOfCompany = company.defaultWorkspace.key === workspace.key
        return appReducer({ user, webapp }, {
            type: 'SWITCH_TO_WORKSPACE_AND_COMPANY',
            company,
            workspace,
            isDefaultContextOfUser,
            isDefaultWorkspaceOfCompany,
            path: actionPath ? actionPath : statePath
        })
    }

    const updateWorkspace = (state, workspace) => {
        const workspacesList = state.workspaces.list.map(item => item.key === workspace.key ? workspace : item)
        const userDefaultWorkspace = state.user.defaultWorkspace.key === workspace.key ? copySettings(workspace, state.user.defaultWorkspace) : state.user.defaultWorkspace
        const contextCompanyDefaultWorkspace = state.context.company.defaultWorkspace.key === workspace.key ? copySettings(workspace, state.context.company.defaultWorkspace) : state.context.company.defaultWorkspace
        const contextWorkspace = state.context.workspace.key === workspace.key ? copySettings(workspace, state.context.workspace) : state.context.workspace
        return updateWorkspacesInState(state, workspacesList, userDefaultWorkspace, contextCompanyDefaultWorkspace, contextWorkspace)
    }

    const setDefaultWorkspace = (state, workspaceKey) => {
        const workspacesList = state.workspaces.list.map(item => ({ ...item, isDefault: item.key === workspaceKey }))
        const newDefaultWorkspace = state.workspaces.list.find(item => item.key === workspaceKey)
        const isAdminImpersonating = isSuperAdminImpersonating(state.user, state.context)
        const userDefaultWorkspace = isAdminImpersonating ? state.user.defaultWorkspace : copySettings(newDefaultWorkspace, state.user.defaultWorkspace)
        const contextCompanyDefaultWorkspace = copySettings(newDefaultWorkspace, state.context.company.defaultWorkspace)
        const contextWorkspace = { ...state.context.workspace, isDefault: state.context.workspace.key === workspaceKey }
        return updateWorkspacesInState(state, workspacesList, userDefaultWorkspace, contextCompanyDefaultWorkspace, contextWorkspace)
    }

    const updateCompanySettings = (state, companySettings) => {
        return {
            ...state,
            context: {
                ...state.context,
                company: {
                    ...state.context.company,
                    settings: companySettings
                }
            }
        }
    }

    const copySettings = (toWorkspace, fromWorkspace) => {
        return {
            ...toWorkspace,
            settings: fromWorkspace.settings
        }
    }

    const updateWorkspacesInState = (state, workspacesList, userDefaultWorkspace, contextCompanyDefaultWorkspace, contextWorkspace) => {
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                list: workspacesList
            },
            user: {
                ...state.user,
                defaultWorkspace: userDefaultWorkspace
            },
            context: {
                ...state.context,
                workspace: contextWorkspace,
                company: {
                    ...state.context.company,
                    defaultWorkspace: contextCompanyDefaultWorkspace
                }
            }
        }
    }

    const statePath = (state) => {
        if (!state) {
            return null
        }
        if (!state.context) {
            return null
        }
        return state.context.exitPath
    }

    return (state, action) => {
        switch (action.type) {
        case 'LOGOUT':
            return appReducer({webapp: state.webapp}, action)
        case 'SWITCH_TO_DEFAULT_WORKSPACE_OF_COMPANY':
            return switchToWorkspaceAndCompany(state.user, action.company.defaultWorkspace, action.company, state.webapp, statePath(state), action.path)
        case 'SWITCH_TO_WORKSPACE_OF_ACTIVE_COMPANY':
            return switchToWorkspaceAndCompany(state.user, action.workspace, state.context.company, state.webapp, statePath(state))
        case 'SWITCH_TO_DEFAULT_COMPANY_AND_WORKSPACE_OF_USER':
            return switchToWorkspaceAndCompany(state.user, state.user.company.defaultWorkspace, state.user.company, state.webapp, statePath(state))
        case 'SWITCH_TO_DEFAULT_WORKSPACE_OF_ACTIVE_COMPANY':
            return switchToWorkspaceAndCompany(state.user, state.context.company.defaultWorkspace, state.context.company, state.webapp, statePath(state))
        case 'SWITCH_TO_WORKSPACE_OF_COMPANY':
            return switchToWorkspaceAndCompany(state.user, action.workspace, action.company, state.webapp, statePath(state), action.path)
        case 'SET_USER':
            return switchToWorkspaceAndCompany(action.payload, action.payload.defaultWorkspace, action.payload.company, state.webapp, statePath(state))
        case 'UPDATE_WORKSPACE':
            return updateWorkspace(state, action.workspace)
        case 'SET_DEFAULT_WORKSPACE':
            return setDefaultWorkspace(state, action.workspaceKey)
        case 'UPDATE_COMPANY_SETTINGS':
            return updateCompanySettings(state, action.settings)
        default:
            return appReducer(state, action)
        }
    }
}

const history = (history) => createReducer(history)
export default history
