import React, { Component } from 'react'
import { setHeader } from '../../actions/header'
import { exposeErrors } from '../../utils/errors'
import { adminService } from '../../services/admin.service'
import { getRegions } from '../../domain/regions'
import { sortBy } from 'lodash'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import lang from '../../utils/lang'
import { cloneObject } from '../../utils/helperFunctions'
import { openModalDialog } from '../../actions/modalDialog'

class AdminJobs extends Component {

    state = {
        jobs: []
    }

    componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
        this.refreshJobs()
    }

    async refreshJobs () {
        let jobsByRegionPromises = getRegions().map(async region => {
            const jobs = await exposeErrors(adminService.getJobs(region.id))
            return {
                region: region.id,
                jobs
            }
        })
        const jobsByRegion = await Promise.all(jobsByRegionPromises)
        const jobsByRegionSorted = sortBy(jobsByRegion, 'region.id')
        this.setState({ jobs: jobsByRegionSorted })
    }

    async enableOrDisable (region, job) {
        this.props.openModalDialog({
            title: this.enableOrDisableModalDialogTitle(job),
            successMessage: this.enableOrDisableModalDialogSuccessMessage(job),
            settings: {
                onAccept: async () => {
                    await this.doEnableOrDisable(job, region)
                },
                acceptCaption: this.enableOrDisableModalDialogAcceptCaption(job),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    enableOrDisableModalDialogTitle (job) {
        if (job.enabled) {
            return 'Disable job?'
        }
        return 'Enable job?'
    }

    enableOrDisableModalDialogAcceptCaption (job) {
        if (job.enabled) {
            return 'Disable'
        }
        return 'Enable'
    }

    enableOrDisableModalDialogSuccessMessage (job) {
        if (job.enabled) {
            return 'Job disabled'
        }
        return 'Job enabled'
    }

    async doEnableOrDisable (job, region) {
        if (job.enabled) {
            this.disableJobInState(region, job.name)
            await exposeErrors(adminService.disableJob(region, job.name))
        } else {
            this.enableJobInState(region, job.name)
            await exposeErrors(adminService.enableJob(region, job.name))
        }
    }

    async trigger (region, job) {
        this.props.openModalDialog({
            title: 'Trigger job?',
            successMessage: 'Job triggered',
            settings: {
                onAccept: async () => {
                    await this.doTrigger(job, region)
                },
                acceptCaption: 'Trigger',
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async doTrigger (job, region) {
        await exposeErrors(adminService.triggerJob(region, job.name))
    }

    render () {
        return <div className="Admin sectioned-page">
            <div className="sectioned-page-container">
                <div className="section">
                    <h1>Jobs</h1>
                    {this.state.jobs.map(jobsByRegion => {
                        return <JobsTable
                            key={'jobs-' + jobsByRegion.region}
                            job={jobsByRegion}
                            onJobEnabledOrDisabled={(job) => this.enableOrDisable(jobsByRegion.region, job)}
                            onJobTriggered={(job) => this.trigger(jobsByRegion.region, job)}
                        />
                    })}
                </div>
            </div>
        </div>
    }

    enableJobInState (region, jobName) {
        this.setJobEnabledInState(region, jobName, true)
    }

    disableJobInState (region, jobName) {
        this.setJobEnabledInState(region, jobName, false)
    }

    setJobEnabledInState (region, jobName, enabled) {
        const copiedJobs = cloneObject(this.state.jobs)
        const job = copiedJobs
            .find(jobsAndRegion => jobsAndRegion.region === region)
            .jobs
            .find(job => job.name === jobName)
        job.enabled = enabled
        this.setState({ jobs: copiedJobs })
    }
}

class JobRowAccordion extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            expanded: false
        }
    }

    toggle () {
        this.setState({ expanded: !this.state.expanded })
    }

    render () {
        return <>
            <tr>
                <td>
                    {(this.props.job.configuration && Object.keys(this.props.job.configuration).length > 0) &&
                        <span className={`icon icon-arrow-medium-down ${this.state.expanded && 'flipped'}`} onClick={() => this.toggle()}/>
                    }
                </td>
                <td>{this.props.job.name}</td>
                <td>{this.props.renderEnableOrDisableCheckmark(this.props.job)}</td>
                <td>{this.props.renderActions(this.props.job)}</td>
            </tr>
            {this.state.expanded &&
                <tr key={this.props.job.name + '-allConfig'}>
                    <td colSpan="4">
                        {this.props.children}
                    </td>
                </tr>
            }
        </>
    }
}

JobRowAccordion.propTypes = {
    job: PropTypes.object,
    children: PropTypes.node,
    renderEnableOrDisableCheckmark: PropTypes.func,
    renderActions: PropTypes.func
}

function JobsTable (props) {
    const job = props.job

    function renderHeaders () {
        return <>
            <th width="2%"></th>
            <th width="30%">{lang.d('name')}</th>
            <th width="5%">{lang.d('enabled')}</th>
            <th width="17%">{lang.d('actions')}</th>
        </>
    }

    function renderActions (job) {
        if (job.canBeTriggered) {
            return renderActionsForTriggeredJob(job)
        }
        return renderActionsForRegularJob(job)
    }

    function renderActionsForTriggeredJob (job) {
        return <button className="FormButton horizontalMargin" onClick={_ => props.onJobTriggered(job)}>Trigger</button>
    }

    function renderActionsForRegularJob (job) {
        if(job.enabled) {
            return <button className="FormButton noMargin danger" onClick={_ => props.onJobEnabledOrDisabled(job)}>Disable</button>
        } else {
            return <button className="FormButton noMargin no-danger" onClick={_ => props.onJobEnabledOrDisabled(job)}>Enable</button>
        }
    }

    function renderEnableOrDisableCheckmark (job) {
        if (job.enabled) {
            return <div className={'icon icon-check'}/>
        }
        return <div className={'icon icon-x'}/>
    }

    function renderJobConfiguration (job) {
        if (job.configuration && Object.keys(job.configuration).length > 0) {
            const rows = []
            Object.entries(job.configuration).forEach(entry => rows.push(<tr key={job.name + '-' + entry[0] + '-config'}>
                <td width="40%">{entry[0]}</td>
                <td width="40%">{entry[1].toString()}</td>
            </tr>))
            return <table>
                <tbody>
                    {rows}
                </tbody>
            </table>
        } else {
            return <></>
        }
    }

    function renderRows () {
        return props.job.jobs.map((job) =>
            <JobRowAccordion key={job.name}
                job={job}
                renderActions={renderActions}
                renderEnableOrDisableCheckmark={renderEnableOrDisableCheckmark}>
                {renderJobConfiguration(job)}
            </JobRowAccordion>
        )
    }

    return <>
        <h2>{job.region}</h2>
        <div className="DataTable">
            <table>
                <thead>
                    <tr>
                        { renderHeaders() }
                    </tr>
                </thead>
                <tbody>
                    { renderRows() }
                </tbody>
            </table>
        </div>
    </>
}

JobsTable.propTypes = {
    job: PropTypes.object,
    onJobEnabledOrDisabled: PropTypes.func,
    onJobTriggered: PropTypes.func
}

AdminJobs.propTypes = {
    setHeader: PropTypes.func,
    openModalDialog: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(AdminJobs)
