import React, { Component } from 'react'
import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import { Redirect, Route, Switch } from 'react-router-dom'

import Home from './routes/Home'
import { PrivateRoute } from './components/CustomRoutes'
import { setupInterceptors } from './utils/network'
import { UserSettings, WorkspaceSettings } from './routes/Settings'
import Account from './routes/Account/Account'
import ChangePlan from './routes/Company/ChangePlan'
import { ChartArchive, ChartDetail, CreateChart, EditChart, ViewPublishedChart } from './routes/Charts'
import {
    AccountNotActive,
    ConfirmEmail,
    ConfirmResetPassword,
    Login,
    Logout,
    ResetPassword,
    Signup,
    SwitchToDefaultCompanyAndWorkspace
} from './routes/Auth'
import Notifications from './components/GUI/Notifications'
import ContextualMenu from './components/GUI/ContextualMenu'
import ContextualHeader from './components/ContextualHeader'
import Intercom from './components/Intercom'
import Reports from './routes/Reports'
import ManageSubscription from './routes/Company/ManageSubscription'
import EventReport from './routes/EventReport'
import PageNotFound from './routes/PageNotFound'
import ErrorBoundary from './components/ErrorBoundary'
import Companies from './routes/Admin/Companies'
import Company from './routes/Admin/Company'
import AdminHome from './routes/Admin/AdminHome'
import AdminUsageReport from './routes/Admin/AdminUsageReport'
import AdminRendererConfigKeysReport from './routes/Admin/AdminRendererConfigKeysReport'
import * as Sentry from '@sentry/browser'
import { Environment } from './environment'
import AdminCopyCharts from './routes/Admin/AdminCopyCharts'
import SwitchToDefaultWorkspace from './routes/Auth/SwitchToDefaultWorkspace'
import { isCompanyOrSuperAdmin, isSuperAdmin } from './reducers/user'
import AdminUserEventSearcher from './routes/Admin/AdminUserEventSearcher'
import AcceptInvitationForNewUser from './routes/Auth/AcceptInvitationForNewUser'
import Team from './routes/Team/Team'
import Workspaces from './routes/Workspaces'
import AcceptInvitationForExistingUser from './routes/Auth/AcceptInvitationForExistingUser'
import SwitchToWorkspace from './routes/Auth/SwitchToWorkspace'
import ModalDialogContainer from './components/ModalDialogContainer'
import AdminBillingReport from './routes/Admin/AdminBillingReport'
import RegionFetcher from './components/RegionFetcher'
import ChartPreview from './routes/Auth/ChartPreview'
import InactiveWorkspaces from './routes/InactiveWorkspaces'
import DeploymentPipelines from './routes/Admin/DeploymentPipelines'
import AdminJobs from './routes/Admin/AdminJobs'
import AdminFindChart from './routes/Admin/AdminLogInAsCustomerThroughChartKey'
import Webhooks from './routes/Webhooks'
import ImmediateUpgrade from './routes/Company/ImmediateUpgrade'
import PaymentDetails from './routes/Company/PaymentDetails'
import RedirectTo from './routes/RedirectTo'
import UpgradeSummary from './routes/Company/UpgradeSummary'
import CompanySettingsNew from "./routes/Settings/CompanySettings";
import EmailTemplates from './routes/Admin/EmailTemplates'
import AdminFeatureAnalyticsReport from "./routes/Admin/AdminFeatureAnalyticsReport";
import EmailCompanies from './routes/Admin/EmailCompanies'
import Billing from './routes/Company/invoicesAndPaymentDetails/Billing'
import AddPaymentMethod from './routes/Company/invoicesAndPaymentDetails/AddPaymentMethod'
import ReactivateForSubscription from './routes/Company/ReactivateForSubscription'
import ModalWizardContainer from './components/ModalWizardContainer'
import TwoFactorAuthenticate from './routes/Auth/TwoFactorAuthenticate'
import OnLoadStateRefresher from './components/OnLoadStateRefresher'
import VersionChecker from './components/VersionChecker'

require('./Webapp.less')

setupInterceptors(history, store)

if (Environment.sentryEnabled) {
    Sentry.init({
        dsn: 'https://dd474db0c702402485c5726d27f6fa6d@sentry.io/1437248',
        environment: process.env.NODE_ENV
    })
}

class App extends Component {
    getBrowserClassName () {
        if (navigator.userAgent.indexOf('Chrome') >= 0) {
            return 'chrome'
        } else if (navigator.userAgent.indexOf('Safari') >= 0) {
            return 'safari'
        } else if (navigator.userAgent.indexOf('Firefox') >= 0) {
            return 'firefox'
        } else {
            return ''
        }
    }

    render () {
        return (
            <Provider store={store}>
                <RegionFetcher>
                    <ErrorBoundary>
                        <>
                            <OnLoadStateRefresher/>
                            <ConnectedRouter history={history}>
                                <>
                                    <Notifications/>
                                    <ContextualMenu/>
                                    <ModalDialogContainer/>
                                    <ModalWizardContainer/>
                                    <Intercom/>

                                    <div className={`App ${this.getBrowserClassName()}`}>
                                        <ContextualHeader />
                                        <Switch>
                                            <PrivateRoute exact path="/" component={Home}/>
                                            <PrivateRoute exact path="/archive" component={ChartArchive}/>
                                            <PrivateRoute exact path="/create-chart" component={CreateChart}/>
                                            <PrivateRoute exact path="/charts/:chartKey" component={ChartDetail}/>
                                            <PrivateRoute path="/charts/:chartKey/edit" component={EditChart}/>
                                            <PrivateRoute path="/charts/:chartKey/view-published" component={ViewPublishedChart}/>
                                            <PrivateRoute path="/charts/:chartKey/events/:eventKey?/:subpage?" component={ChartDetail}/>
                                            <PrivateRoute exact path="/workspaces" component={Workspaces}/>
                                            <PrivateRoute exact path="/workspaces/inactive" component={InactiveWorkspaces}/>
                                            <PrivateRoute exact path="/workspace-settings" component={WorkspaceSettings} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/reports" component={Reports} />
                                            <PrivateRoute exact path="/reports/:chartKey/:year/:month/:eventId/:eventKey" component={EventReport} />
                                            <PrivateRoute exact path="/account" component={Account}/>
                                            <PrivateRoute exact path="/account/upgrade-now" component={ImmediateUpgrade} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/account/payment-details" component={PaymentDetails} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/account/upgrade-summary" component={UpgradeSummary} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/account/change-plan" component={ChangePlan} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/account/subscription" component={ManageSubscription} role={isCompanyOrSuperAdmin} />
                                            <PrivateRoute exact path="/team" component={Team} role={isCompanyOrSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/switch-to-workspace/:key" component={SwitchToWorkspace}/>
                                            <PrivateRoute exact path="/user-settings" component={UserSettings}/>
                                            <PrivateRoute exact path="/company-settings" component={CompanySettingsNew} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/webhooks" component={Webhooks} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/company/add-payment-method" component={AddPaymentMethod} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/company/billing" component={Billing} role={isCompanyOrSuperAdmin}/>
                                            <PrivateRoute exact path="/redirect-to" component={RedirectTo} role={isCompanyOrSuperAdmin}/>

                                            <PrivateRoute exact path="/admin" component={AdminHome} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/companies" component={Companies} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/company/:region/:id" component={Company} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/usage-report" component={AdminUsageReport} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/usage-report/:year/:month" component={AdminUsageReport} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/billing-report" component={AdminBillingReport} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/billing-report/:year/:month" component={AdminBillingReport} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/renderer-config-report" component={AdminRendererConfigKeysReport} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/feature-analytics-report" component={AdminFeatureAnalyticsReport} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/deployment-pipeline/:app" component={DeploymentPipelines} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/copy-charts" component={AdminCopyCharts} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/jobs" component={AdminJobs} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/search-user-events" component={AdminUserEventSearcher} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/log-in-as-customer-through-chart-key" component={AdminFindChart} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/email-templates" component={EmailTemplates} role={isSuperAdmin}/> {/* eslint-disable-line jsx-a11y/aria-role */}
                                            <PrivateRoute exact path="/admin/email-companies" component={EmailCompanies} role={isSuperAdmin}/>{/* eslint-disable-line jsx-a11y/aria-role */}

                                            <Route exact path="/login" component={Login}/>
                                            <Route exact path="/authenticate" component={TwoFactorAuthenticate}/>
                                            <Route exact path="/signup" component={Signup}/>
                                            <Route exact path="/reactivate-for-subscription" component={ReactivateForSubscription}/>
                                            <Route exact path="/preview/:region/:workspaceKey/:chartKey" component={ChartPreview} />
                                            <Route exact path="/reset-password" component={ResetPassword}/>
                                            <Route exact path="/password/enter" component={ConfirmResetPassword}/>
                                            <Route exact path="/logout" component={Logout}/>
                                            <Route exact path="/switch-to-default-company-and-workspace" component={SwitchToDefaultCompanyAndWorkspace}/>
                                            <Route exact path="/switch-to-default-workspace" component={SwitchToDefaultWorkspace}/>
                                            <Route exact path="/account-not-active" component={AccountNotActive}/>
                                            <Route exact path="/confirm-email/:confirmationCode" component={ConfirmEmail}/>
                                            <Route exact path="/accept-invitation/:code" component={AcceptInvitationForNewUser}/>
                                            <Route exact path="/accept-invitation-for-existing-user/:code" component={AcceptInvitationForExistingUser}/>

                                            <Route exact path="/invoices">
                                                <Redirect to="/company/billing#invoices"/>
                                            </Route>
                                            <Route exact path="/update-billing-details">
                                                <Redirect to="/company/billing#billing_details"/>
                                            </Route>
                                            <Route exact path="/update-payment-method">
                                                <Redirect to="/add-payment-method"/>
                                            </Route>

                                            <Route component={PageNotFound}/>
                                        </Switch>
                                    </div>
                                </>
                            </ConnectedRouter>
                        </>
                    </ErrorBoundary>
                </RegionFetcher>
            </Provider>
        )
    }
}

export default App
