import { Component } from 'react'
import { getLatestVersion } from '../services/webapp.service'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateVersion } from '../actions/webapp'
import * as React from 'react'
import debounce from 'lodash/debounce'

class VersionChecker extends Component {

    constructor (props) {
        super(props)
        this.debouncedCheckVersion = debounce(this.checkVersion, 100)
    }

    async componentDidMount () {
        await this.checkVersion()
    }


    async componentDidUpdate (prevProps, prevState, snapshot) {
        await this.debouncedCheckVersion()
    }

    async checkVersion () {
        const currentVersion = this.props.currentVersion
        const latestVersion = await getLatestVersion()
        if (!latestVersion || currentVersion.version !== latestVersion.version) {
            this.props.updateVersion(latestVersion)
            setTimeout(() => {
                window.location.reload()
            }, 1500)
        }
    }

    render () {
        return <>
            {this.props.children !== undefined && this.props.children}
        </>
    }
}

VersionChecker.propTypes = {
    updateVersion: PropTypes.func,
    currentVersion: PropTypes.string,
    children: React.ReactNode | undefined
}

function mapStateToProps (state) {
    return {
        currentVersion: state.webapp
    }
}

const mapDispatchToProps = dispatch => ({
    updateVersion: payload => dispatch(updateVersion(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(VersionChecker)
