export const webappDefaultState = {
    version: undefined
}

const webapp = (state = webappDefaultState, action) => {
    switch (action.type) {
    case 'UPDATE_WEBAPP_VERSION':
        return {
            ...action.payload
        }
    default:
        return state
    }
}

export default webapp
