import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createAppReducer from './reducers'
import throttle from 'lodash/throttle'
import { loadFromStorageToState, saveToStorageFromState } from './utils/localStorage'
import { Environment } from './environment'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

function ensureContext (state) {
    if (state && state.user && state.user.liveSupportEnabled === undefined) {
        state.user.liveSupportEnabled = true
    }
    return state
}

const persistedState = ensureContext(loadFromStorageToState())

const enhancers = []
const middleware = [
    thunk,
    routerMiddleware(history)
]

if (Environment.isDev) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension({ trace: true, traceLimit: 25 }))
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const store = createStore(
    createAppReducer(history),
    persistedState,
    composedEnhancers
)

// throttle ensures storage can only be written to once per second
store.subscribe(throttle(() => {
    saveToStorageFromState({
        user: store.getState().user,
        context: store.getState().context,
        webapp: store.getState().webapp
    })
}, 0))

if (window.Cypress) {
    window.store = store
}

export default store
