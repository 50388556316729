import axios from 'axios'

export {
    getLatestVersion
}

async function getLatestVersion () {
    const result = await axios.create({})({
        method: 'get',
        url: '/version',
    })
    return result.data
}
