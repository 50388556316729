import React from 'react'
import * as PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { useAsync } from 'react-async'
import { Environment } from '../environment'
import { getAccount } from '../services/accounts.service'
import SplashScreenNotFound from './GUI/SplashScreenNotFound'
import SplashScreenGeneralErrorModalDialog from './GUI/SplashScreenGeneralErrorModalDialog'
import { isSuperAdmin } from '../reducers/user'
import { adminService } from '../services/admin.service'
import { setSuperAdminEnvironmentSettings } from '../domain/superAdminEnvironmentSettings'
import VersionChecker from './VersionChecker'

const hasRequiredRole = (user, role) => {
    return !role || role(user)
}

export const PrivateRoute = ({ role, path, render, location, component: Component, ...rest }) => {
    const user = useSelector(state => state.user)

    const { data: account, error: getAccountError } = useAsync({ promiseFn: getAccount })

    const { data: superAdminEnvironmentSettings, error: getSuperAdminEnvironmentSettingsError } = useAsync({
        promiseFn: getEnvironmentSettingsAsSuperAdmin,
        user
    })
    setSuperAdminEnvironmentSettings(superAdminEnvironmentSettings)

    if (!user) return <Redirect to={{ pathname: '/login', state: { from: location } }}/>

    if (Environment.sentryEnabled) {
        Sentry.getCurrentScope().setExtra('email', user.email)
    }

    if (account && superAdminEnvironmentSettings) {
        if (!hasRequiredRole(account, role)) {
            return <SplashScreenNotFound/>
        }
        return <Route render={(props) => Component ? <VersionChecker><Component {...props} {...rest}/></VersionChecker> : <VersionChecker>{render(props)}</VersionChecker>}
            role={role} {...rest} />
    }

    if (getAccountError) {
        if (getAccountError.status === 403) {
            return <Redirect
                to={{ pathname: '/account-not-active', state: { reason: getAccountError.errors[0].data.reason } }}/>
        } else {
            console.error(getAccountError)
            return <SplashScreenGeneralErrorModalDialog/>
        }
    }

    if (getSuperAdminEnvironmentSettingsError) {
        console.error(getSuperAdminEnvironmentSettingsError)
        return <SplashScreenGeneralErrorModalDialog/>
    }

    return null
}

const getEnvironmentSettingsAsSuperAdmin = ({ user }) => {
    if (isSuperAdmin(user)) {
        return adminService.getEnvironmentSettings()
    }
    return Promise.resolve({})
}

PrivateRoute.propTypes = {
    role: PropTypes.func,
    path: PropTypes.string,
    location: PropTypes.object,
    component: PropTypes.any,
    render: PropTypes.func
}
